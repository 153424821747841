@import 'style/colors';

.Nav {
    font-family: 'Roboto', sans-serif;
    display: flex;
    height: 56px;

    &-left,
    &-right {
        display: flex;
    }

    &-right {
        justify-content: flex-end;
        align-items: center;
    }

    &-links {
        display: flex;

        .MuiLink-root {
            display: flex;
            align-items: center;
            line-height: 1;
            text-align: center;
            margin: 0 12px;
            padding: 14px 0;
            border: none;
            border-bottom: 3px solid transparent;

            &.active {
                &:hover {
                    filter: none;
                }
            }

            &.disabled {
                cursor: default;
            }
        }
    }

    .MuiLink-root.logo {
        border-bottom: none;
        &:hover {
            filter: none;
        }
    }

    .photo-user-org,
    .photo-user {
        cursor: pointer;
    }
}

.Nav-Desktop {
    padding: 0 48px;
    .Nav-left {
        flex: 1;
    }
    .MuiLink-root.logo {
        margin: 0 56px 0 0;

        &.no-right-margin {
            margin-right: 0;
        }
    }
}

.Nav-Mobile {
    padding: 0 16px;
    .Nav-left {
        align-items: center;
    }
    .Nav-center {
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
    }
}
