@import 'style/mixins.less';
@import 'style/colors_DEPRECATED.less';
@import 'style/typeface.less';

.party {
    height: 100%;
    width: 100%;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:extend(.body4_sans-reg_white-center);
    background-color: @grayscale-7;
    &.selected {
        &.buyer {
            background-color: @primary1-3;
        }
        &.seller {
            background-color: @primary3-3;
        }
    }
}

.icon-conveyance-with-background() {
    padding: 8px;
    fill: white;
    border-radius: 2px;
}

.icon-truck {
    &.with-background {
        .icon-conveyance-with-background();
        background-color: @primary1-3;
    }
}

.icon-vessel {
    &.with-background {
        .icon-conveyance-with-background();
        background-color: @primary1-4;
    }
}

.icon-rail {
    &.with-background {
        .icon-conveyance-with-background();
        background-color: @primary4-3;
    }
}

.icon-onsite {
    &.with-background {
        .icon-conveyance-with-background();
        background-color: @primary3-3;
    }
}
