@import 'style/colors';

.Button {
    svg {
        fill: currentColor;
    }

    .Button-textContent {
        border-bottom: 1px solid;
    }
}

.MuiButton-root.Mui-disabled {
    color: @grey70;
    .Button-textContent {
        border-color: @grey40;
    }
}
