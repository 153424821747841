@import 'colors_DEPRECATED.less';
@import 'constants.less';

.elevation1() {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(0, 0, 0, 0.03);
}

.elevation2() {
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
}

// not from zeplin: used for modal footer
.elevation3() {
    filter: drop-shadow(0 0px 20px @grayscale-1-10pct);
}

.elevation3flat() {
    box-shadow: 0 1px 2px 0 rgba(32, 36, 38, 0.1);
}

.elevation4a() {
    box-shadow: 0 2px 4px 0 rgba(32, 36, 38, 0.1), 0 8px 24px 0 rgba(32, 36, 38, 0.2), 0 0 0 1px rgba(32, 36, 38, 0.05);
}

.elevation4b() {
    box-shadow: 0 -2px 4px 0 rgba(32, 36, 38, 0.1), 0 -8px 24px 0 rgba(32, 36, 38, 0.1),
        0 0 0 1px rgba(32, 36, 38, 0.05);
}

.elevation5() {
    box-shadow: 0 0 0 1px rgba(32, 36, 38, 0.05), 0 16px 40px 0 rgba(32, 36, 38, 0.2),
        0 4px 12px 0 rgba(32, 36, 38, 0.1);
    border: 1px solid rgba(32, 36, 38, 0.05);
}

.pin-striped(@color) {
    background: repeating-linear-gradient(45deg, transparent, transparent 1px, rgba(@color, 0.3) 2px, transparent 3px);
}

.interactionColors(@hoverColor, @shadowColor) {
    &:not([disabled]) {
        &:hover {
            background-color: @hoverColor;
        }

        &:active {
            box-shadow: inset 0 2px 0 0 @shadowColor;
        }
    }
}

.hideScrollbar() {
    // Chrome / Edge / Safari
    &::-webkit-scrollbar {
        display: none;
    }

    // Firefox
    scrollbar-width: none;

    // IE
    -ms-overflow-style: none;
}
