@import 'mixins.less';

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.base-family {
    font-family: 'Roboto', sans-serif;
}

// --------------------
// BODY1
// --------------------
.body1 {
    &:extend(.base-family);
    font-size: 18px;
    line-height: 1.11;
    letter-spacing: normal;
}

.body1_mono-reg_dark {
    &:extend(.body1);
    font-family: 'IBM Plex Mono', monospace;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.11;
    color: @grayscale-2;
}

.body1_mono-reg_dark-right {
    &:extend(.body1_mono-reg_dark);
    text-align: right;
}

.body1_sans-reg {
    &:extend(.body1);
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
}

.body1_sans-reg_dark {
    &:extend(.body1_sans-reg);
    color: @grayscale-2;
}

.body1_sans-reg_dark-center {
    &:extend(.body1_sans-reg_dark);
    text-align: center;
}

.body1_sans-reg_dark-left {
    &:extend(.body1_sans-reg_dark);
    text-align: left;
}

.body1_sans-reg_white-left {
    &:extend(.body1_sans-reg);
    color: @grayscale-10;
    text-align: left;
}

.body1_sans-reg_white-center {
    &:extend(.body1_sans-reg);
    color: @grayscale-10;
    text-align: center;
}

// --------------------
// BODY2
// --------------------
.body2 {
    letter-spacing: 0;
    font-size: 16px;
    &:extend(.base-family);
    line-height: 18px;
}

.body2_mono-reg {
    &:extend(.body2);
    font-family: 'IBM Plex Mono', monospace;
    font-feature-settings: 'ss04';
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
}

.body2_mono-reg_dark {
    &:extend(.body2_mono-reg);
    color: @grayscale-2;
}

.body2_sans-reg {
    &:extend(.body2);
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
}

.body2_sans-reg_gray {
    &:extend(.body2_sans-reg);
    color: @grayscale-5;
}

.body2_sans-reg_dark {
    &:extend(.body2_sans-reg);
    color: @grayscale-2;
}

.body2_sans-reg_white {
    &:extend(.body2_sans-reg);
    color: @grayscale-10;
}

.body2_sans-reg_dark-center {
    &:extend(.body2_sans-reg_dark);
    text-align: center;
}

.body2_sans-reg_white-center {
    &:extend(.body2_sans-reg_white);
    text-align: center;
}

.body2_sans-reg_dark-left {
    &:extend(.body2_sans-reg_dark);
    text-align: left;
}

// --------------------
// BODY3
// --------------------
.body3 {
    letter-spacing: 0;
    font-size: 14px;
    line-height: 16px;
    &:extend(.base-family);
}

.body3_mono-reg {
    &:extend(.body3);
    font-family: 'IBM Plex Mono', monospace;
    font-feature-settings: 'ss04'; // use the plain zero instead of the dotted or slashed zero
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
}

.body3_mono-reg-italic {
    &:extend(.body3_mono-reg);
    font-style: italic;
}

.body3_mono-reg_dark {
    &:extend(.body3_mono-reg);
    color: @grayscale-2;
}

.body3_mono-reg_white {
    &:extend(.body3_mono-reg);
    color: @grayscale-10;
}

.body3_mono-reg_white-right {
    &:extend(.body3_mono-reg_white);
    text-align: right;
}

.body3_mono-reg_dark-right {
    &:extend(.body3_mono-reg_dark);
    text-align: right;
}

.body3_mono-reg_dark-right-italic {
    &:extend(.body3_mono-reg_dark-right);
    font-style: italic;
}

.body3_mono-reg_dark-left {
    &:extend(.body3_mono-reg_dark);
    text-align: left;
}

.body3_mono-reg_grey {
    &:extend(.body3_mono-reg);
    color: @grayscale-4;
}

.body3_mono-reg_grey-right {
    &:extend(.body3_mono-reg_grey);
    text-align: right;
}

.body3_mono-reg_grey-left {
    &:extend(.body3_mono-reg_grey);
    text-align: left;
}

.body3_mono-reg_light-right {
    &:extend(.body3_mono-reg);
    color: @grayscale-5;
    text-align: right;
}

.body3_mono-reg_light-light-right {
    &:extend(.body3_mono-reg_light-right);
    color: @grayscale-7;
}

.body3_mono-semi-bold {
    font-family: 'IBM Plex Mono', monospace;
    font-feature-settings: 'zero';
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
}

.body3_mono-semi-bold_dark {
    &:extend(.body3_mono-semi-bold);
    color: @grayscale-2;
}

.body3_mono-semi-bold_color-right {
    &:extend(.body3_mono-semi-bold);
    text-align: right;
    color: @primary1-3;
}

.body3_sans-reg {
    &:extend(.body3);
    text-align: left;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
}

.body3_sans-reg_color {
    &:extend(.body3_sans-reg);
    color: @primary1-3;
}

.body3_sans-reg_color-left {
    &:extend(.body3_sans-reg_color);
    text-align: left;
}

.body3_sans-reg_color-center {
    &:extend(.body3_sans-reg_color);
    text-align: center;
}

.body3_sans-reg_color-left-italic {
    &:extend(.body3_sans-reg_color-left);
    font-style: italic;
}

.body3_sans-reg_color-right {
    &:extend(.body3_sans-reg_color);
    text-align: right;
}

.body3_sans-reg_color2-center {
    &:extend(.body3_sans-reg);
    color: @primary1-4;
    text-align: center;
}

.body3_sans-reg_dark {
    &:extend(.body3_sans-reg);
    color: @grayscale-2;
}

.body3_sans-reg_dark-medium {
    &:extend(.body3_sans-reg_dark);
    font-weight: 500;
}

.body3_sans-reg_dark-medium-italic {
    &:extend(.body3_sans-reg_dark-medium);
    font-weight: 500;
    font-style: italic;
}

.body3_sans-reg_dark-center {
    &:extend(.body3_sans-reg_dark);
    text-align: center;
}

.body3_sans-reg_dark-left {
    &:extend(.body3_sans-reg_dark);
    text-align: left;
}

.body3_sans-reg_dark-right {
    &:extend(.body3_sans-reg_dark);
    text-align: right;
}

.body3_sans-reg_dark-italic {
    &:extend(.body3_sans-reg_dark);
    font-style: italic;
}

.body3_sans-reg_error {
    &:extend(.body3_sans-reg);
    color: @error;
}

.body3_sans-reg_error-left {
    &:extend(.body3_sans-reg_error);
    text-align: left;
}

.body3_sans-reg_error-right {
    &:extend(.body3_sans-reg_error);
    text-align: right;
}

.body3_sans-reg_error-left-italic {
    &:extend(.body3_sans-reg_error-left);
    font-style: italic;
}

.body3_sans-reg_grey {
    &:extend(.body3_sans-reg);
    color: @grayscale-4;
}

.body3_sans-reg_grey-italic {
    &:extend(.body3_sans-reg_grey);
    font-style: italic;
}

.body3_sans-reg_grey-center {
    &:extend(.body3_sans-reg_grey);
    text-align: center;
}

.body3_sans-reg_grey-center-italic {
    &:extend(.body3_sans-reg_grey-center);
    font-style: italic;
}

.body3_sans-reg_grey-left {
    &:extend(.body3_sans-reg_grey);
    text-align: left;
}

.body3_sans-reg_grey-left-italic {
    &:extend(.body3_sans-reg_grey-left);
    font-style: italic;
}

.body3_sans-reg_grey-left-medium {
    &:extend(.body3_sans-reg_grey-left);
    font-weight: 500;
}

.body3_sans-reg_dark-left-medium {
    &:extend(.body3_sans-reg_dark-left);
    font-weight: 500;
}

.body3_sans-reg_grey-right {
    &:extend(.body3_sans-reg_grey);
    text-align: right;
}

.body3_sans-reg_grey-right-italic {
    &:extend(.body3_sans-reg_grey);
    text-align: right;
    font-style: italic;
}

.body3_sans-reg_grey-right-medium {
    &:extend(.body3_sans-reg_grey-right);
    font-weight: 500;
}

.body3_sans-reg_light-grey-left {
    &:extend(.body3_sans-reg);
    color: @grayscale-5;
    text-align: left;
}

.body3_sans-reg_light-left {
    &:extend(.body3_sans-reg);
    color: @grayscale-5;
}

.body3_sans-reg_white {
    &:extend(.body3_sans-reg);
    color: @grayscale-10;
}

.body3_sans-reg_white-center {
    &:extend(.body3_sans-reg_white);
    text-align: center;
}

.body3_sans-reg_white-left {
    &:extend(.body3_sans-reg_white);
    text-align: left;
}

.body3_sans-reg_white-right {
    &:extend(.body3_sans-reg_white);
    text-align: right;
}

// --------------------
// BODY4
// --------------------
.body4 {
    &:extend(.base-family);
    letter-spacing: 0;
    font-size: 12px;
    line-height: 16px;
}

.body4_mono-reg {
    &:extend(.body4);
    font-family: 'IBM Plex Mono', monospace;
    font-feature-settings: 'ss04';
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
}

.body4_mono-reg_dark {
    &:extend(.body4_mono-reg);
    color: @grayscale-2;
}

.body4_mono-reg_gray {
    &:extend(.body4_mono-reg);
    color: @grayscale-4;
}

.body4_mono-reg_dark-italic {
    &:extend(.body4_mono-reg_dark);
    font-style: italic;
}

.body4_mono-semi-bold {
    font-family: 'IBM Plex Mono', monospace;
    font-feature-settings: 'zero';
    font-size: 12px;
    letter-spacing: 1.8px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    color: @grayscale-10;
}

.body4_mono-semi-bold_dark {
    &:extend(.body4_mono-semi-bold);
    color: @grayscale-2;
}

.body4_sans-ital_grey {
    &:extend(.body4);
    font-style: italic;
    color: @grayscale-4;
}

.body4_sans-reg {
    &:extend(.body4);
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
}

.body4_sans-reg_dark {
    &:extend(.body4_sans-reg);
    color: @grayscale-2;
    font-weight: normal;
}

.body4_sans-reg_color-right-italic {
    &:extend(.body4_sans-reg);
    color: @primary1-3;
    font-style: italic;
}

.body4_sans-reg_grey {
    &:extend(.body4_sans-reg);
    color: @grayscale-4;
}

.body4_sans-reg_light-grey {
    &:extend(.body4_sans-reg);
    color: @grayscale-5;
}

.body4_sans-reg_grey-center {
    &:extend(.body4_sans-reg_grey);
    text-align: center;
}

.body4_sans-reg_error-left-italic {
    &:extend(.body4_sans-reg);
    font-style: italic;
    color: @error;
    text-align: left;
}

.body4_sans-reg_grey-italic {
    &:extend(.body4_sans-reg_grey);
    font-style: italic;
}

.body4_sans-reg_light-grey-italic {
    &:extend(.body4_sans-reg_light-grey);
    font-style: italic;
}

.body4_sans-reg_grey-center-italic {
    &:extend(.body4_sans-reg_grey-italic);
    text-align: center;
}

.body4_sans-reg_grey-right-italic {
    &:extend(.body4_sans-reg_grey-italic);
    text-align: right;
}

.body4_sans-reg_grey-left {
    &:extend(.body4_sans-reg);
    color: @grayscale-4;
    text-align: left;
}

.body4_sans-reg_grey-left-italic {
    &:extend(.body4_sans-reg_grey-italic);
    text-align: left;
}

.body4_sans-reg_white {
    &:extend(.body4_sans-reg);
    color: @grayscale-10;
}

.body4_sans-reg_white-center {
    &:extend(.body4_sans-reg_white);
    text-align: center;
}

.body4_sans-reg_white-center-medium {
    &:extend(.body4_sans-reg_white-center);
    letter-spacing: 1.8px;
    line-height: 1;
}

.body4_sans-reg_white-left {
    &:extend(.body4_sans-reg_white);
    text-align: left;
}

.body4_sans-reg_white-right {
    &:extend(.body4_sans-reg_white);
    text-align: right;
}

.body4_sans-reg_white-right-italic {
    &:extend(.body4_sans-reg_white-right);
    font-style: italic;
}

// --------------------
// BODY5
// --------------------
.body5 {
    &:extend(.base-family);
    letter-spacing: 0;
    font-size: 10px;
    line-height: 12px;
}

.body5_sans-reg {
    &:extend(.body5);
    font-weight: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: normal;
}

.body5_sans-reg-italic {
    &:extend(.body5);
    font-style: italic;
}

.body5_sans-reg_color-center-italic {
    &:extend(.body5_sans-reg-italic);
    color: @primary1-3;
    text-align: center;
}

.body5_sans-reg_grey {
    &:extend(.body5_sans-reg);
    color: @grayscale-4;
}

.body5_sans-reg_grey-left {
    &:extend(.body5_sans-reg_grey);
    text-align: left;
}

.body5_sans-reg_grey-left-italic {
    &:extend(.body5_sans-reg-italic);
    color: @grayscale-4;
    text-align: left;
}

.body5_sans-reg_grey-right {
    &:extend(.body5_sans-reg);
    color: @grayscale-4;
    text-align: right;
}

.body5_sans-reg_grey-right-italic {
    &:extend(.body5_sans-reg_grey-right);
    font-style: italic;
}

.body5_sans-reg_dark {
    &:extend(.body5_sans-reg);
    color: @grayscale-2;
}

.body5_sans-reg_light-blue-left {
    &:extend(.body5);
    color: @primary1-4;
    text-align: left;
}

.body5_sans-reg_white {
    &:extend(.body5_sans-reg);
    color: @grayscale-10;
}

.body5_sans-reg_white-medium {
    &:extend(.body5_sans-reg-white);
    font-weight: 500;
}

.body5_sans-reg_white-right {
    &:extend(.body5_sans-reg_white);
    text-align: right;
}

.body5_sans-reg_white-right-italic {
    &:extend(.body5_sans-reg_white-right);
    font-style: italic;
}

.body5_sans-semi-bold {
    &:extend(.body5);
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    font-stretch: normal;
    line-height: 10px;
    letter-spacing: 1.67px;
}

.body5_sans-semi-bold_dark {
    &:extend(.body5_sans-semi-bold);
    color: @grayscale-2;
}

.body5_sans-semi-bold_color {
    &:extend(.body5_sans-semi-bold);
    color: @primary1-3;
}

.body5_sans-bold {
    &:extend(.body5_sans-reg);
    font-weight: 700;
}

.body5_mono-reg {
    &:extend(.body5);
    font-family: 'IBM Plex Mono', monospace;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
}

.body5_mono-reg_dark {
    &:extend(.body5_mono-reg);
    color: @grayscale-2;
}

.body5_mono-reg_dark-italic {
    &:extend(.body5_mono-reg_dark);
    font-style: italic;
}

.body5_mono-reg_gray {
    &:extend(.body5_mono-reg);
    color: @grayscale-4;
}

.body5_mono-reg_light-gray-left {
    &:extend(.body5_mono-reg);
    color: @grayscale-6;
}

.body5_mono-reg_light-gray-right {
    &:extend(.body5_mono-reg);
    color: @grayscale-6;
    text-align: right;
}

// --------------------
// CAP0
// --------------------
.cap0_sans-reg {
    &:extend(.base-family);
    font-size: 14px;
    line-height: 12px;
    letter-spacing: 2.1px;
    font-weight: 600;
    text-transform: uppercase;
}

.cap0_sans-reg_white-left {
    &:extend(.cap0_sans-reg);
    color: @grayscale-10;
    text-align: left;
}

.cap0_sans_reg_center {
    &:extend(.cap0_sans-reg);
    text-align: center;
}

.CaptionCap0Sans-RegDark-Left {
    &:extend(.base-family);
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.86;
    letter-spacing: 2.1px;
    color: @grayscale-1;
}

// --------------------
// CAP1
// --------------------
.cap1 {
    &:extend(.base-family);
    font-size: 12px;
    letter-spacing: 1.8px;
    line-height: 1;
}

.cap1_mono-reg {
    font-family: 'IBM Plex Mono', monospace;
    font-feature-settings: 'zero'; // use the slashed zero instead of the plain or dotted zero
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 1.8px;
    line-height: 12px;
}

.cap1_mono-reg_grey-left {
    &:extend(.cap1_mono-reg);
    color: @grayscale-4;
    text-align: left;
}

.cap1_mono-reg_light {
    &:extend(.cap1_mono-reg);
    color: @grayscale-10;
}

.cap1_mono-reg_light-left {
    &:extend(.cap1_mono-reg_light);
    text-align: left;
}

.cap1_mono-reg_light-right {
    &:extend(.cap1_mono-reg_light);
    text-align: right;
}

.cap1_sans-bold_light_left {
    &:extend(.cap1_sans-reg);
    color: @grayscale-10;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 3px;
}

.cap1_sans-reg {
    &:extend(.cap1);
    line-height: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
}

.cap1_sans-reg_white-left {
    &:extend(.cap1_sans-reg);
    text-transform: uppercase;
    color: @grayscale-10;
    text-align: left;
}

.cap1_sans-reg_black-center {
    &:extend(.cap1_sans-reg);
    color: @grayscale-1;
    text-align: center;
}

.cap1_sans-reg_color {
    &:extend(.cap1_sans-reg);
    text-transform: uppercase;
    color: @primary1-3;
}

.cap1_sans-reg_color-center {
    &:extend(.cap1_sans-reg_color);
    text-align: center;
}

.cap1_sans-reg_color-left {
    &:extend(.cap1_sans-reg_color);
    text-align: left;
}

.cap1_sans-reg_dark {
    &:extend(.cap1_sans-reg);
    color: @grayscale-2;
}

.cap1_sans-reg_dark-left {
    &:extend(.cap1_sans-reg_dark);
    text-align: left;
}

.cap1_sans-reg_dark-right {
    &:extend(.cap1_sans-reg_dark);
    text-align: right;
}

.cap1_sans-reg_grey {
    &:extend(.cap1_sans-reg);
    color: @grayscale-4;
}

.cap1_sans-reg_grey-center {
    &:extend(.cap1_sans-reg_grey);
    text-align: center;
}

.cap1_sans-reg_grey-left {
    &:extend(.cap1_sans-reg_grey);
    text-align: left;
}

.cap1_sans-reg_light {
    &:extend(.cap1_sans-reg);
    color: @grayscale-10;
}

.cap1_sans-reg_light-center {
    &:extend(.cap1_sans-reg_light);
    text-align: center;
}

.cap1_sans-reg_light-left {
    &:extend(.cap1_sans-reg_light);
    text-align: left;
}

.caption1_sans_reg_black_center {
    &:extend(.cap1_sans-reg);
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 1.8px;
    text-align: center;
    color: @grayscale-1;
    font-weight: 600;
}

// --------------------
// CAP2
// --------------------
.cap2_mono-reg_grey-center {
    &:extend(.cap2);
    font-family: 'IBM Plex Mono', monospace;
    font-feature-settings: 'zero';
    text-align: center;
    color: @grayscale-4;
}

.cap2_sans-reg {
    &:extend(.cap1_sans-reg);
    font-size: 10px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 10px;
    letter-spacing: 1.67px;
    text-transform: uppercase;
}

.cap2_sans-reg_dark {
    &:extend(.cap2_sans-reg);
    color: @grayscale-2;
}

.cap2_sans-reg_dark-left {
    &:extend(.cap2_sans-reg_dark);
    text-align: left;
}

.cap2_sans-reg_color-center {
    &:extend(.cap2_sans-reg);
    text-align: center;
    color: @primary1-3;
}

.cap2_sans-reg_color-left {
    &:extend(.cap2_sans-reg_color-center);
    text-align: left;
}

.cap2_sans-reg_green {
    &:extend(.cap2_sans-reg);
    color: @primary3-3;
}

.cap2_sans-reg_grey {
    &:extend(.cap2_sans-reg);
    color: @grayscale-4;
}

.cap2_sans-reg_grey-center {
    &:extend(.cap2_sans-reg_grey);
    text-align: center;
    color: @grayscale-4;
}

.cap2_sans-reg_grey-left {
    &:extend(.cap2_sans-reg_grey);
    text-align: left;
}

.cap2_sans-reg_light-grey {
    &:extend(.cap2_sans-reg);
    color: @grayscale-6;
}

.cap2_sans-reg_light-grey-left {
    &:extend(.cap2_sans-reg_light-grey);
    text-align: left;
}

.cap2_sans-reg_light-blue-left {
    &:extend(.cap2_sans-reg);
    text-align: left;
    color: @primary1-4;
}

.cap2_sans-reg_neon-left {
    &:extend(.cap2_sans-reg);
    text-align: left;
    color: @primary3-4;
}

.cap2_sans-reg_white {
    &:extend(.cap2_sans-reg);
    color: @grayscale-10;
}

.cap2_sans-reg_white-center {
    &:extend(.cap2_sans-reg_white);
    text-align: center;
}

.cap2_sans-reg_white-left {
    &:extend(.cap2_sans-reg_white);
    text-align: left;
}

.cap2_sans-reg_white-right {
    &:extend(.cap2_sans-reg_white);
    text-align: right;
}

// --------------------
// HEADDDDERS
// --------------------
.header3_sans-reg_dark-center {
    &:extend(.base-family);
    font-size: 34px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: @grayscale-2;
}

.header3_sans-reg_dark-left {
    &:extend(.base-family);
    font-size: 34px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: @grayscale-2;
}

.header3_sans-reg_light-left {
    &:extend(.header3_sans-reg_dark-left);
    color: @grayscale-10;
}

.header4_sans-reg_dark-left {
    &:extend(.base-family);
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: @grayscale-2;
}

.header4_sans-reg_dark-center {
    &:extend(.header4_sans-reg_dark-left);
    text-align: center;
}

.header4_sans-reg_light-center {
    &:extend(.base-family);
    font-size: 24px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: @grayscale-10;
    opacity: 0.8;
}

.header6_sans-reg_dark-left {
    &:extend(.base-family);
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: @grayscale-2;
}
