@import 'style/colors_DEPRECATED';
@import 'style/mixins';
@import 'style/typeface';
@import 'style/constants';

// make it easy for tooltip triggers to show a pointer
.tooltip-trigger {
    cursor: pointer;
}

.guide-tooltip {
    &:extend(.body3_sans-reg_dark-left);
    background-color: @grayscale-10;

    display: flex;
    flex-direction: column;
    justify-items: center;
    align-content: flex-start;

    padding-top: 14px;
    padding-bottom: 12px;
    padding-left: 16px;
    padding-right: 16px;

    .guide-header {
        &:extend(.cap2_sans-reg_grey-left);
        padding-bottom: 5px;
    }
}

.tippy-box[data-theme~='info'] {
    padding: 0;
    max-width: 224px;
    .elevation4a();
    text-transform: none;
    display: flex;
    z-index: @tooltips;
    font-feature-settings: 'none'; // use the plain zero instead of the dotted or slashed zero

    background-color: @grayscale-1;
    color: @grayscale-10;
    &:extend(.body4_sans-reg_white-left);
    border-radius: 2px;
}

.tippy-box[data-theme~='guide'] {
    padding: 0;
    max-width: 224px;
    .elevation4a();
    text-transform: none;
    z-index: @tooltips;
    font-feature-settings: 'none'; // use the plain zero instead of the dotted or slashed zero

    &:extend(.body3_sans-reg_dark-left);
    background-color: @grayscale-10;

    display: flex;
    flex-direction: column;
    justify-items: center;
    align-content: flex-start;

    padding-top: 14px;
    padding-bottom: 12px;
    padding-left: 16px;
    padding-right: 16px;

    color: @grayscale-1;
    &:extend(.body4_sans-reg_dark-left);
    border-radius: 2px;

    .guide-header {
        &:extend(.cap2_sans-reg_grey-left);
        padding-bottom: 5px;
    }
}

.tippy-box[data-theme~='scheduling'] {
    padding: 0;
    max-width: 224px;
    .elevation4a();
    z-index: @tooltips;
    background-color: @grayscale-10;
    border-radius: 2px;

    .tippy-content {
        padding: 0;
    }
}
