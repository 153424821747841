@import 'style/typeface.less';
@import 'style/colors_DEPRECATED.less';
@import 'style/mixins.less';

.base-snackbar {
    padding: 8px;
    width: 100%;
    background-color: transparent;
    box-shadow: none;
}

.snackbar {
    border-radius: 4px;
    min-height: 32px;
    padding: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    &.small {
        width: 344px;
    }

    .snackbar-left {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    &.v-update {
        &:extend(.body3_sans-reg_white-left);
        background-color: @primary1-3;
    }

    &.v-update-light {
        &:extend(.body3_sans-reg_white-left);
        background-color: @primary1-4;
    }

    &.v-error {
        &:extend(.body3_sans-reg_white-left);
        background-color: @error;
    }

    &.v-success {
        &:extend(.body3_sans-reg_white-left);
        background-color: @primary3-3b;
    }

    &.v-warning {
        &:extend(.body3_sans-reg_error-left-italic);
        background-color: rgb(@error, 10%);

        svg {
            fill: @error;
        }
    }

    .snackbar-icon {
        width: 16px;
        height: 16px;
        padding-right: 8px;
        fill: @grayscale-10;

        .svg {
            fill: currentColor;
        }
    }
}

// react-toastify hard codes with to 320px except for
// mobile, which is 100vw.
// We want 100vw on mobile and 50vw otherwise.
@media only screen and (min-width: 480px) {
    .Toastify__toast-container {
        width: 60vw;
        padding: 0;
        left: 0;
        margin: 0;
    }
}

.Toastify__toast-body {
    background-color: #faeded;
}

.storybook-styling {
    width: 100px;
    text-transform: uppercase;
    color: @primary1-3;
}
