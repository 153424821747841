@import './reset';
@import './colors';

body {
    background-color: white;
    margin: 0;

    /* Typography */
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    line-height: 24px;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    --page-background: @white;

    &.WUIGreyPage {
        --page-background: @grey20;
        background-color: @grey20;
    }
    &.WUIWhitePage {
        --page-background: @white;
        background-color: @white;
    }
}

.OldDesignSystem {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 14px;
    line-height: 16px;

    a:not(.MuiLink-root, .MuiButtonBase-root) {
        color: @blue50;
    }
}

html,
body,
#root {
    height: 100%;
    width: 100%;
}

@media print {
    * {
        overflow: visible !important;
    }

    body {
        -webkit-print-color-adjust: exact;
    }
}
